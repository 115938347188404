import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import privacyImage from './privacy_policy.jpg'
import './CSS/Privacy.css'

class Terms extends Component {
   componentDidMount(){
    window.scrollTo(0, 0)

   }
    render() {
        return (
            <div className="Privacy-Container">
                <Navbar />
                <h1 style={{textAlign:"center",color:"white",position:"absolute",marginLeft:"43%",marginTop:"10%",fontWeight:"bolder"}}>Terms of Services</h1>

                <div className="Privacy-Body-Container">
                    <div style={{maxWidth:'100%',maxHeight:300,backgroundColor:'#8b0000',position:'flxed'}}>
                        <img alt='privacy not found' src={privacyImage} style={{width:'100%',height:300,opacity:0.2}} />
                    </div>
                    <div className="Privacy-Body">
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    <p>
                    ESTE ES UN ACUERDO ENTRE USTED O LA ENTIDAD QUE REPRESENTA
(en adelante "Usted" o "Su") Y Mi Consignación (myconsignment.co), Mi Consignación
(miconsignación.com, miconsignacion.com), Pure Artisan (pureartisan.co), Puro Artesanal
(puroartesanal.com), Alberto Juan (www.albertojuandesigns.com), Treasure Trove NYC
(treasuretrovenyc.com) y etsy.tube (en adelante "EL PROVEEDOR") QUE GOBIERNA SU
USO DEL PROVEEDOR DE SITIOS WEB Y APLICACIONES DE NEGOCIOS EN LÍNEA. </p>
                <h1>Partes de este Acuerdo</h1>
                <p>Este Acuerdo consta de los siguientes términos y condiciones (en adelante, los "Términos Generales")
y términos y condiciones.</p>
                <h1>Aceptación de los términos</h1>
                <p>Debe ser mayor de edad para celebrar un acuerdo vinculante para aceptar los Términos. Si tu
no acepte los Términos generales, no use ninguno de nuestros Servicios. Puedes aceptar los Términos por
marcando una casilla de verificación o haciendo clic en un botón que indica su aceptación de los términos o por
en realidad usando los Servicios.</p>
                    <h1>Descripción del Servicio</h1>
                    <p>Proporcionamos una variedad de servicios para aplicaciones y sitios web comerciales en línea (Servicio o
  Y servicios). Puede usar los Servicios para su uso personal y comercial o para negocios internos
propósito en la organización que representa. Puede conectarse a los Servicios utilizando cualquier
Navegador de Internet compatible con los Servicios. Usted es responsable de obtener acceso a
Internet y el equipo necesario para utilizar los Servicios. Puede crear y editar contenido con
su cuenta de usuario y si elige hacerlo, puede publicar y compartir dicho contenido.</p>
            <h1>Modificación de los Términos de Servicio</h1>
            <p>Podemos modificar los Términos mediante notificación a usted en cualquier momento a través de un anuncio de servicio o por
enviando correo electrónico a su dirección de correo electrónico principal. Si hacemos cambios significativos en los Términos que
afectará sus derechos, se le notificará con al menos 30 días de anticipación de los cambios por
correo electrónico a su dirección de correo electrónico principal. Puede terminar su uso de los Servicios proporcionando
EL PROVEEDOR notificará por correo electrónico dentro de los 30 días posteriores a la notificación de la disponibilidad de

Términos modificados si los Términos se modifican de una manera que afecta sustancialmente sus derechos en
conexión con el uso de los Servicios. En caso de dicha terminación, tendrá derecho a
reembolso prorrateado de la parte no utilizada de cualquier tarifa prepaga. Su uso continuado del Servicio
después de la fecha de vigencia de cualquier cambio en los Términos, se considerará que usted acepta el
Términos modificados.</p>
            <h1>Obligaciones de registro del usuario</h1>
            <p>Debe registrarse para obtener una cuenta de usuario proporcionando toda la información requerida para acceder o
usar los servicios. Si representa a una organización y desea utilizar los Servicios para empresas
uso interno, le recomendamos que usted y todos los demás usuarios de su organización se registren como usuario
cuentas al proporcionar su información de contacto corporativa. En particular, le recomendamos que
use su dirección de correo electrónico corporativo. Usted acepta: a) proporcionar información verdadera, precisa, actual y completa
información sobre usted según lo solicite el proceso de registro; y b) mantener y puntualmente
Actualice la información provista durante el registro para mantenerla verdadera, precisa, actual y completa. Si
proporciona cualquier información que sea falsa, inexacta, desactualizada o incompleta, o si
El PROVEEDOR tiene motivos razonables para sospechar que dicha información es falsa, inexacta,
desactualizado o incompleto, EL PROVEEDOR puede cancelar su cuenta de usuario y rechazar
o el uso futuro de cualquiera o de todos los Servicios, sobre los cuales se deben todas las tarifas y pagos (todos y cada uno
las transacciones pendientes serán canceladas).</p>
            <h1>Cuentas de organización y administradores</h1>
            <p>Cuando se registra para obtener una cuenta para su organización, puede especificar uno o más
administradores Los administradores tendrán derecho a configurar los Servicios en función de su
requisitos y administrar usuarios finales en la cuenta de su organización. Si la cuenta de su organización es
creado y configurado en su nombre por un tercero, es probable que dicho tercero tenga
asumió el rol de administrador para su organización. Asegúrese de ingresar en un
acuerdo con dicho tercero especificando los roles y restricciones de tal parte como administrador
de la cuenta de su organización.
Usted es responsable de i) garantizar la confidencialidad de la contraseña de la cuenta de su organización, ii)
nombrar personas competentes como administradores para administrar la cuenta de su organización, y
iii) asegurar que todas las actividades que ocurran en relación con la cuenta de su organización cumplan
con este Acuerdo Usted comprende que EL PROVEEDOR no es responsable de la cuenta.
Administración y gestión interna de los Servicios para usted.
Usted es responsable de tomar las medidas necesarias para garantizar que su organización no pierda
control de las cuentas de administrador. Puede especificar un proceso a seguir para recuperar
control en caso de tal pérdida de control de las cuentas de administrador enviando un correo electrónico
a treasure.trove.nyc@gmail.com, siempre que el proceso sea aceptable para EL PROVEEDOR.
En ausencia de un proceso de recuperación de cuenta de administrador específico, EL PROVEEDOR puede
proporcionar el control de una cuenta de administrador a un individuo que proporcione pruebas satisfactorias para THE
PROVEEDOR que demuestre autorización para actuar en nombre de la organización. Usted acepta no
responsabilizar al PROVEEDOR por las consecuencias de cualquier acción tomada por EL PROVEEDOR en
buena fe a este respecto..</p>
                <h1>Información personal y privacidad</h1>
                <p>La información personal que proporciona al PROVEEDOR a través del Servicio se rige por THE
POLÍTICA DE PRIVACIDAD DEL PROVEEDOR. Su elección de usar el Servicio indica su aceptación de
los términos de la POLÍTICA DE PRIVACIDAD DEL PROVEEDOR. Usted es responsable de mantener
confidencialidad de su nombre de usuario, contraseña y otra información confidencial. Tu eres responsable
para todas las actividades que ocurran en su cuenta de usuario y usted acepta informarnos inmediatamente de cualquier
uso no autorizado de su cuenta de usuario por correo electrónico a treasure.trove.nyc@gmail.com. No somos
responsable de cualquier pérdida o daño a usted o a un tercero incurrido como resultado de cualquier
acceso y / o uso no autorizado de su cuenta de usuario, o de otra manera.</p>
                <h1>Comunicaciones del PROVEEDOR</h1>
                <p>El Servicio puede incluir ciertas comunicaciones del PROVEEDOR, como el servicio
anuncios, mensajes administrativos y boletines informativos. Entiendes que estos
Las comunicaciones se considerarán parte del uso de los Servicios. Como parte de nuestra política para proporcionar
su total privacidad, también le ofrecemos la opción de optar por no recibir boletines de
nosotros. Sin embargo, no podrá optar por no recibir anuncios de servicio y
mensajes administrativos..</p>
                <h1>Quejas</h1>
                <p>Si recibimos una queja de alguna persona en su contra con respecto a sus actividades como parte de
uso de los Servicios, investigaremos el problema a fondo y tomaremos las medidas apropiadas (nosotros
se reserva el derecho de suspender o cancelar sus cuentas por cualquier motivo).</p>
        <h1>Spamming y actividades ilegales</h1>
        <p>Usted acepta ser el único responsable del contenido de sus transmisiones a través de los Servicios.
Usted acepta no usar los Servicios para fines ilegales o para la transmisión de material que sea
ilegal, difamatorio, acosador, calumnioso, invasivo de otra privacidad, abusivo, amenazante,
dañino, vulgar, pornográfico, obsceno, o de otra manera es objetable, ofende a los religiosos
sentimientos, promueve el racismo, contiene virus o código malicioso, o lo que infringe o puede
infringir la propiedad intelectual u otros derechos de otro. Usted acepta no usar los Servicios para

transmisión de correo basura, spam, cadenas de cartas, "phishing" o distribución masiva no solicitada
de correo electrónico. Nos reservamos el derecho de cancelar su acceso a los Servicios si existen
motivos para creer que ha utilizado los Servicios para cualquier actividad ilegal o no autorizada.</p>
        <h1>Política de cuentas de usuario inactivo</h1>
        <p>Nos reservamos el derecho de cancelar las cuentas de usuario impagas que están inactivas por un período continuo
de 120 días En caso de dicha terminación, todos los datos asociados con dicha cuenta de usuario serán
eliminado Le enviaremos un aviso previo de dicha terminación y la opción de hacer una copia de seguridad de sus datos.
La política de eliminación de datos puede implementarse con respecto a cualquiera o todos los Servicios. Cada
El servicio se considerará un servicio independiente y separado con el fin de calcular el
período de inactividad. En otras palabras, la actividad en uno de los Servicios no es suficiente para mantener su
cuenta de usuario en otro servicio activo. En el caso de cuentas con más de un usuario, si al menos
uno de los usuarios está activo, la cuenta no se considerará inactiva.</p>
        <h1>Propiedad de datos</h1>
        <p>Usted es el único responsable de crear copias de seguridad y reemplazar su Contenido de usuario en su
Único costo y gasto. Usted reconoce y acepta que el Proveedor no es responsable de ninguna
errores u omisiones que realice en relación con los Servicios. Al enviar o publicar usuario
Contenido, por la presente otorga al Proveedor un servicio no exclusivo, transferible, libre de regalías, perpetuo,
derecho irrevocable, sublicenciable de usar, reproducir, modificar, adaptar, publicar, traducir, vender, crear
trabajos derivados, distribuir, realizar y mostrar el Contenido del usuario y su nombre,
nombre de la empresa, ubicación y cualquier otra información que envíe con el Contenido del usuario, en
conexión con los Servicios. El uso de su nombre de usuario o de cualquier otro, semejanza o identidad en
la conexión con el Proveedor y / o los Servicios no implica ningún respaldo a menos que
explícitamente declarado de otra manera. También tenemos el derecho de revelar su identidad a terceros.
quien afirma que cualquier contenido publicado por usted constituye una violación de su intelectual
derechos de propiedad, o de su derecho a la privacidad.</p>
        <h1>Contenido generado por el usuario</h1>
        <p>Puede transmitir o publicar contenido creado por usted usando cualquiera de los Servicios o de otra manera.
Sin embargo, usted será el único responsable de dicho contenido y de las consecuencias de su contenido.
transmisión o publicación. Cualquier contenido hecho público será accesible públicamente a través de
Internet y puede ser rastreado e indexado por los motores de búsqueda. Usted es responsable de garantizar
que accidentalmente no hace público ningún contenido privado. Cualquier contenido que tu
puede recibir de otros usuarios de los Servicios, se le proporciona TAL CUAL es para su información y
uso personal solamente y usted acepta no usar, copiar, reproducir, distribuir, transmitir, transmitir,
exhibir, vender, licenciar o explotar dicho contenido para cualquier propósito, sin el expreso
consentimiento por escrito de la persona que posee los derechos de dicho contenido. En el curso de usar cualquiera de
los Servicios, si encuentra algún contenido con aviso (s) de copyright o alguna protección contra copia
características, usted acepta no eliminar dichos avisos de derechos de autor o deshabilitar dicha protección de copia
característica (s) según sea el caso. Al hacer que cualquier contenido con derechos de autor / derechos de autor esté disponible en cualquier
de los Servicios, usted afirma que tiene el consentimiento, autorización o permiso, según sea el caso
puede ser de cada persona que pueda reclamar cualquier derecho sobre dicho contenido para hacer dicho contenido

disponible de tal manera. Además, al hacer que cualquier contenido esté disponible de la manera
antes mencionado, usted acepta expresamente que EL PROVEEDOR tendrá derecho a bloquear el acceso a
o eliminar dicho contenido puesto a su disposición si EL PROVEEDOR recibe quejas
sobre cualquier ilegalidad o infracción de los derechos de terceros en dicho contenido. Al usar cualquiera de
los Servicios y la transmisión o publicación de cualquier contenido utilizando dicho Servicio, usted expresamente
consentir a la determinación de cuestiones de ilegalidad o infracción de derechos de terceros en tales
contenido por el agente designado por EL PROVEEDOR para este propósito.</p>
        <h1>Archivos de muestra y aplicaciones</h1>
        <p>EL PROVEEDOR puede proporcionar archivos de muestra y aplicaciones con el fin de demostrar
la posibilidad de usar los Servicios de manera efectiva para fines específicos. La información contenida
en cualquiera de estos archivos de muestra y aplicaciones consta de datos aleatorios. EL PROVEEDOR no hace
garantía, ya sea expresa o implícita, en cuanto a la precisión, utilidad, integridad o confiabilidad de
la información o los archivos y aplicaciones de muestra.</p>
    <h1>Marca comercial</h1>
    <p>EL PROVEEDOR, el logotipo del PROVEEDOR, los nombres de los Servicios individuales y sus logotipos son
marcas registradas de THE PROVIDER Corporation. Usted acepta no mostrar ni usar, de ninguna manera, el
Las marcas registradas de THE PROVIDER, sin el permiso previo por escrito de THE PROVIDER.</p>
    <h1>Descargo de responsabilidad de garantías</h1>
    <p>USTED ENTIENDE Y ACEPTA EXPRESAMENTE QUE EL USO DE LOS SERVICIOS ES
A SU PROPIO RIESGO. LOS SERVICIOS SE OFRECEN TAL CUAL Y COMO ESTÁN
BASE DISPONIBLE. EL PROVEEDOR RECHAZA EXPRESAMENTE TODAS LAS GARANTÍAS DE
CUALQUIER TIPO, EXPRESO O IMPLÍCITO, INCLUYENDO, PERO SIN LIMITARSE A,
LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD Y APTITUD PARA UN
PROPÓSITO PARTICULAR. EL PROVEEDOR NO GARANTIZA QUE EL
LOS SERVICIOS SERÁN ININTERRUMPIDOS, OPORTUNOS, SEGUROS O SIN ERRORES. USO DE
CUALQUIER MATERIAL DESCARGADO U OBTENIDO MEDIANTE EL USO DEL
LOS SERVICIOS SERÁN A SU PROPIA DISCRECIÓN Y RIESGO Y USTED SERÁ
SÓLO RESPONSABLE DE CUALQUIER DAÑO A SU SISTEMA INFORMÁTICO, MÓVIL
TELÉFONO, DISPOSITIVO INALÁMBRICO O DATOS RESULTANTES DEL USO DEL
SERVICIOS O LA DESCARGA DE CUALQUIER MATERIAL. SIN CONSEJOS O
INFORMACIÓN, ESCRITA U ORAL, OBTENIDA POR USTED DEL
EL PROVEEDOR, SUS EMPLEADOS O REPRESENTANTES CREARÁN CUALQUIERA
GARANTÍA NO EXPRESAMENTE EXPRESADA EN LOS TÉRMINOS.</p>
<h1>Limitación de responsabilidad</h1>
<p>USTED ACEPTA QUE EL PROVEEDOR, EN NINGÚN CASO, SERÁ RESPONSABLE DE NINGÚN TIPO
PÉRDIDA CONSECUENTE, INCIDENTAL, INDIRECTA, ESPECIAL, PUNITIVA U OTRA
O DAÑO CUALQUIERA O POR PÉRDIDA DE BENEFICIOS EMPRESARIALES, NEGOCIOS
INTERRUPCIÓN, FALLO DE LA COMPUTADORA, PÉRDIDA DE INFORMACIÓN COMERCIAL O
OTRAS PÉRDIDAS DERIVADAS O CAUSADAS POR SU USO O INHABILIDAD DE

UTILICE EL SERVICIO, INCLUSO SI EL PROVEEDOR HA SIDO INFORMADO DE LA
POSIBILIDAD DE TALES DAÑOS. EN NINGÚN CASO TODO EL PROVEEDOR
RESPONSABILIDAD CON USTED RESPECTO A CUALQUIER SERVICIO, DIRECTO O
INDIRECTO, EXCEDE LAS TARIFAS PAGADAS POR USTED HACIA DICHO SERVICIO.</p>
<h1>Indemnización</h1>
<p>Usted acepta indemnizar y eximir de responsabilidad al PROVEEDOR, sus funcionarios, directores, empleados,
proveedores y afiliados, de y contra pérdidas, daños, multas y gastos (incluidos
honorarios y costos de abogados) que surjan de o estén relacionados con cualquier reclamo de que ha utilizado los Servicios
en violación de los derechos de otra parte, en violación de cualquier ley, en violación de cualquier disposición de
los Términos, o cualquier otro reclamo relacionado con su uso de los Servicios, excepto cuando dicho uso sea
autorizado por EL PROVEEDOR.</p>
<h1>Suspensión y Terminación</h1>
<p>Podemos suspender su cuenta de usuario o deshabilitar temporalmente el acceso a todo o parte de cualquier Servicio
en caso de sospecha de actividad ilegal, períodos prolongados de inactividad o solicitudes por ley
cumplimiento u otras agencias gubernamentales. Objeciones a la suspensión o inhabilitación del usuario.
las cuentas deben hacerse a treasure.trove.nyc@gmail.com dentro de los treinta días posteriores a la notificación
Sobre la suspensión. Podemos cancelar una cuenta de usuario suspendida o deshabilitada después de treinta días.
También cancelaremos su cuenta de usuario cuando lo solicite.
Además, nos reservamos el derecho de cancelar su cuenta de usuario y denegar los Servicios cuando
creencia razonable de que ha violado los Términos y de rescindir su acceso a cualquier Beta
Servicio en caso de problemas técnicos inesperados o interrupción del Servicio Beta. Tienes
El derecho a cancelar su cuenta de usuario si EL PROVEEDOR incumple sus obligaciones bajo estos
Términos y en tal caso, tendrá derecho a un reembolso prorrateado de cualquier tarifa prepaga. Terminación
de la cuenta de usuario incluirá la denegación de acceso a todos los Servicios, la eliminación de información en su usuario
cuenta como su dirección de correo electrónico y contraseña y la eliminación de todos los datos en su cuenta de usuario.</p>
<h1>FIN DE LOS TÉRMINOS DE SERVICIO</h1>
<p>Si tiene alguna pregunta o inquietud con respecto a este Acuerdo, contáctenos
en treasure.trove.nyc@gmail.com</p>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}



export default Terms;
